import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getAllPartners (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/channel-partners', {
      params: serverQueryObj
    })
  },

  async getPartner (partnerId) {
    return await axios.get(`/channel-partners/${partnerId}`)
  },

  async createPartner (partner) {
    return await axios.post('/channel-partners', partner)
  },

  async updatePartner (partner) {
    return await axios.patch(`/channel-partners/${partner.id}`, partner)
  },

  async deletePartner (partnerId) {
    return await axios.delete(`/channel-partners/${partnerId}`)
  },

  async getAssignedPartner (serviceId) {
    return await axios.get(`/services/${serviceId}/partners`)
  },

  async getAllAssignedServices (partnerId) {
    return await axios.get(`/channel-partners/${partnerId}/assign-services`)
  },

  async assignServices (partnerId, services) {
    return await axios.post(`/channel-partners/${partnerId}/assign-services`, services)
  }
}
