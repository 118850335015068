import state from './modulePoiState.js'
import mutations from './modulePoiMutations.js'
import actions from './modulePoiActions.js'
import getters from './modulePoiGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
