import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getCountryDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/countries/datatable', {
      params: serverQueryObj
    })
  },

  async getAllCountries () {
    return await axios.get('/countries')
  },

  // Get All State of Single Country
  async getAllStates (countryId) {
    return await axios.get(`/countries/${countryId}/states`)
  },

  // Get All Banks of Single Country
  async getAllBanks (countryId) {
    return await axios.get(`/countries/${countryId}/banks`)
  }
}
