import state from './moduleCurrencyState.js'
import mutations from './moduleCurrencyMutations.js'
import actions from './moduleCurrencyActions.js'
import getters from './moduleCurrencyGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
