import bankService from '@/services/BankService'

export default {
  getBankDatatable ({commit}, {
    eventForServer,
    filter
  }) {
    return bankService.getBankDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_BANKS', data.payload)
        return data
      })
  },

  getAllBanks ({commit}) {
    return bankService.getAllBanks()
      .then(({data}) => {
        // commit('SET_BANKS', data.payload)
        return data
      })
  }
}
