import axios from '../http/axios'
import { generateParams } from '@/helpers/helper'

export default {
  /*
   * ----------------------------------------------------------------------------
   * --------------------------------  Admin  -----------------------------------
   * ----------------------------------------------------------------------------
   */
  async getFundRequestDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/fund-requests/datatable', {
      params: serverQueryObj
    })
  },

  async getClientAvailableFundDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/client-funds/datatable', {
      params: serverQueryObj
    })
  },

  async getFundHistoryDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/fund-history/datatable', {
      params: serverQueryObj
    })
  },

  async changeFundState (fundRequestId, status) {
    return await axios.patch(`/fund-requests/${fundRequestId}/change-status`, { status })
  },

  /*
   * ----------------------------------------------------------------------------
   * ------------------------------- Client Admin -------------------------------
   * ----------------------------------------------------------------------------
   */
  async getAvailableFundDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/available-funds/datatable', {
      params: serverQueryObj
    })
  },

  async getClientFundHistoryDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/client-fund-history/datatable', {
      params: serverQueryObj
    })
  },

  async createRequestedFund (requestedFund) {
    return await axios.post('/fund-requests', requestedFund)
  }
}
