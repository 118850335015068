import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getServiceDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('services/datatable', {
      params: serverQueryObj
    })
  },

  async getAllService () {
    return await axios.get('services')
  },

  async createService (service) {
    return await axios.post('/services', service)
  },

  async updateService (service) {
    return await axios.patch(`/services/${service.service_id}`, service)
  },

  async deleteService (serviceId) {
    return await axios.delete(`/services/${serviceId}`)
  }
}
