import quoteService from '@/services/QuoteService'

export default {
  getPartnerQuoteDatatable ({commit}, {
    eventForServer,
    filter
  }) {
    return quoteService.getPartnerQuoteDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_QUOTES', data.payload)
        return data
      })
  },

  getClientQuoteDatatable ({commit}, {
    eventForServer,
    filter
  }) {
    return quoteService.getClientQuoteDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_QUOTES', data.payload)
        return data
      })
  },

  getQuote ({commit}, payload) {
    return quoteService.getQuote(payload)
      .then(({data}) => {
        // commit('SET_QUOTE', data.payload)
        return data
      })
  },

  createQuote ({commit}, payload) {
    return quoteService.createQuote(payload)
      .then(({data}) => {
        // commit('CREATE_QUOTE', data.payload)
        return data
      })
  },

  deleteQuote ({commit}, quoteId) {
    return quoteService.deleteQuote(quoteId)
      .then(({data}) => {
        commit('DELETE_QUOTE', quoteId)
        return data
      })
  },

  validateQuote ({commit}, payload) {
    return quoteService.validateQuote(payload)
      .then(({data}) => {
        return data
      })
  },

  quoteService ({commit}, payload) {
    return quoteService.quoteService(payload)
      .then(({data}) => {
        return data
      })
  },

  changeStatusQuote ({commit}, payload) {
    return quoteService.changeStatusQuote(payload)
      .then(({data}) => {
        return data
      })
  }
}
