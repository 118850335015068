import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getOrderDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('orders/datatable', {
      params: serverQueryObj
    })
  },

  async createOrder (order) {
    return await axios.post('/orders', order)
  },

  async getOrder (orderId) {
    return await axios.get(`/orders/${orderId}`)
  },

  async updateOrder (order) {
    return await axios.patch(`/orders/${order.id}`, order)
  },

  async deleteOrder (orderId) {
    return await axios.delete(`/orders/${orderId}`)
  },

  /*
   * Delete Single Order Detail
   */
  async deleteOrderDetail (orderDetailId) {
    return await axios.delete(`/order-details/${orderDetailId}`)
  },

  /*
  * Order Step 3
  */
  async orderService (payload) {
    return await axios.post('/orders/service', payload)
  }
}
