import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getDriverDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('drivers/datatable', {
      params: serverQueryObj
    })
  },

  async getAllDrivers (filter) {
    return await axios.get('/drivers', {
      params: filter
    })
  },

  async getDriver (driverId) {
    return await axios.get(`/drivers/${driverId}`)
  },

  async createDriver (driver) {
    return await axios.post('/drivers', driver)
  },

  async updateDriver (driver) {
    return await axios.patch(`/drivers/${driver.id}`, driver)
  },

  async deleteDriver (driverId) {
    return await axios.delete(`/drivers/${driverId}`)
  },

  async assignTruck ({
    driver_id,
    truck_id
  }) {
    return await axios.patch(`/drivers/${driver_id}/assign`, {truck_id})
  }
}
