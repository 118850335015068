import axios from '../../http/axios'


export default {

  changePassword (contex, payload) {
    return axios.post('/user/password-change', payload)
  }

}
