import service from '@/services/RouteService'

export default {
  getRouteDatatable ({commit}, {eventForServer, filter}) {
    return service.getRouteDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_ROUTES', data.payload)
        return data
      })
  },

  getAllRoutes ({commit}) {
    return service.getAllRoutes()
      .then(({data}) => {
        // commit('CREATE_SERVICE', data.payload)
        return data
      })
  },

  createRoute ({commit}, payload) {
    return service.createRoute(payload)
      .then(({data}) => {
        // commit('CREATE_SERVICE', data.payload)
        return data
      })
  },

  updateRoute ({commit}, payload) {
    return service.updateRoute(payload)
      .then(({data}) => {
        // commit('CREATE_SERVICE', data.payload)
        return data
      })
  },

  getAllService ({commit}) {
    return service.getAllService()
      .then(({data}) => {
        commit('SET_SERVICES', data.payload)
        return data
      })
  },


  updateService ({commit}, payload) {
    return service.updateService(payload)
      .then(({data}) => {
        commit('UPDATE_SERVICE', data.payload)
        return data
      })
  },

  deleteRoute ({commit}, routeId) {
    return service.deleteRoute(routeId)
      .then(() => {
        commit('DELETE_ROUTE', routeId)
      })
  }
}
