export default {
  computed: {
    isAdmin () {
      return (this.$acl.get[0] === 'admin')
    },

    isChannelPartner () {
      return (this.$acl.get[0] === 'channel_partner')
    },

    isClient () {
      return (this.$acl.get[0] === 'client')
    },

    isClientEmployee () {
      return (this.$acl.get[0] === 'client_employee')
    },

    isChannelPartnerEmployee () {
      return (this.$acl.get[0] === 'channel_partner_employee')
    }
  }
}
