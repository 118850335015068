import clientService from '@/services/ClientService'

export default {
  getClientDatatable ({commit}, {eventForServer, filter}) {
    return clientService.getClientDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_CLIENTS', data.payload)
        return data
      })
  },

  getClient ({commit}, clientId) {
    return clientService.getClient(clientId)
      .then(({data}) => {
        commit('SET_CLIENT', data.payload)
        return data
      })
  },

  createClient ({commit}, payload) {
    return clientService.createClient(payload)
      .then(({data}) => {
        commit('CREATE_CLIENT', data.payload)
        return data
      })
  },

  updateClient ({commit}, payload) {
    return clientService.updateClient(payload)
      .then(({data}) => {
        commit('UPDATE_CLIENT', data.payload)
        return data
      })
  },

  deleteClient ({commit}, clientId) {
    return clientService.deleteClient(clientId)
      .then(() => commit('DELETE_CLIENT', clientId))
  }
}
