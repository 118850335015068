import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'
import {Loader} from '@googlemaps/js-api-loader'

export default {
  async getRouteDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('routes/datatable', {
      params: serverQueryObj
    })
  },

  async getAllRoutes () {
    return await axios.get('/routes')
  },

  async createRoute (route) {
    return await axios.post('/routes', route)
  },

  async getAllService () {
    return await axios.get('services')
  },


  async updateRoute (route) {
    return await axios.patch(`/routes/${route.route_id}`, route)
  },

  async getSingleRoute (routeId) {
    return await axios.get(`/routes/${routeId}`)
  },

  async deleteRoute (routeId) {
    return await axios.delete(`/routes/${routeId}`)
  },

  async getDistanceFromGoogle (sourceLat, sourceLong, destinationLat, destinationLong) {

    return new Promise((resolve, reject) => {
      const loader = new Loader({
        apiKey: 'AIzaSyDxO6MWyFrtjk5tYLXZkbH5sMifyVzvlII',
        version: 'weekly'

      })
      loader.load().then(() => {
        const origin1 = new google.maps.LatLng(sourceLat, sourceLong)
        // const origin2 = 'Greenwich, England'
        // const destinationA = 'Stockholm, Sweden'
        const destinationB = new google.maps.LatLng(destinationLat, destinationLong)
        // function callback (response, status) {
        //   console.log(response)
        //
        //   // See Parsing the Results for
        //   // the basics of a callback function.
        // }
        const service = new google.maps.DirectionsService()
        service.route(
          {
            origin: origin1,
            destination: destinationB,
            travelMode: google.maps.TravelMode.DRIVING


          }, function (response, status) {
            //ZERO_RESULTS => error
            if (status === 'ZERO_RESULTS') {
              return reject('No path found')
            }
            const distance = (response.routes[0].legs[0].distance.value) / 1000
            console.log(distance)
            return resolve(distance)
            // console.log(response)
            // console.log(status)
          })


      })
    })


    // const url = `http://maps.googleapis.com/maps/api/directions/json?key=AIzaSyDxO6MWyFrtjk5tYLXZkbH5sMifyVzvlII&origins=${sourceLat},${sourceLong}&destinations=${destinationLat},${destinationLong}`
    // const googleDistanceResponse = await axios.get(`${url}`)
    // console.log(googleDistanceResponse)
  }
}
