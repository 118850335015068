import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getPartnerQuoteDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('quotes/datatable', {
      params: serverQueryObj
    })
  },

  async getClientQuoteDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('quotes/list/client', {
      params: serverQueryObj
    })
  },

  async getQuote (quote) {
    return await axios.post('/quotes/show', quote)
  },

  async createQuote (quote) {
    return await axios.post('/quotes', quote)
  },

  async deleteQuote (quoteId) {
    return await axios.delete(`/quotes/${quoteId}`)
  },

  async validateQuote (order) {
    return await axios.post('/quotes/validate', order)
  },

  async quoteService (quote) {
    return await axios.post(`/quotes/${quote.id}/service`)
  },

  async changeStatusQuote (quote) {
    return await axios.patch(`/quotes/${quote.id}`, quote)
  }
}
