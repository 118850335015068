import axios from '../../axios.js'

// Request interceptor
axios.interceptors.request.use(request => {
    // const token = store.getters['auth/token'];
    const token = localStorage.getItem('accessToken');

    if (token) {
        request.headers.common['Authorization'] = `Bearer ${token}`
    }

    // const locale = store.getters['lang/locale']
    // if (locale) {
    //     request.headers.common['Accept-Language'] = locale
    // }

    // request.headers['X-Socket-Id'] = Echo.socketId()

    return request
})



export default axios
