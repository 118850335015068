/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import moduleAuth from './auth/moduleAuth.js'
import moduleUser from './user/moduleUser.js'
import moduleService from './service/moduleService.js'
import modulePartner from './partner/modulePartner.js'
import modulePoi from './poi/modulePoi.js'
import moduleClient from './client/moduleClient.js'
import moduleTruck from './truck/moduleTruck.js'
import moduleTrailer from './trailer/moduleTrailer.js'
import moduleDriver from './driver/moduleDriver.js'
import moduleEmployee from './employee/moduleEmployee.js'
import moduleRoute from './route/moduleRoute.js'
import modulePartnerServiceDetail from './partner-service-detail/modulePartnerServiceDetail.js'
import moduleOrder from './order/moduleOrder.js'
import modulePendingOrder from './pending-order/modulePendigOrder.js'
import moduleQuote from './quote/moduleQuote.js'
import moduleCountry from './country/moduleCountry.js'
import moduleState from './state/moduleState.js'
import moduleCity from './city/moduleCity.js'
import moduleBank from './bank/moduleBank.js'
import moduleMaintenance from './maintenance/moduleMaintenance.js'
import moduleCurrency from './currency/moduleCurrency.js'
import moduleAdminBankAccount from './admin-bank-account/moduleAdminBankAccount.js'
import moduleFund from './fund/moduleFund.js'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    'auth': moduleAuth,
    'user': moduleUser,
    'service': moduleService,
    'partner': modulePartner,
    'client': moduleClient,
    'truck': moduleTruck,
    'trailer': moduleTrailer,
    'poi': modulePoi,
    'driver': moduleDriver,
    'employee': moduleEmployee,
    'route': moduleRoute,
    'partner-service-detail': modulePartnerServiceDetail,
    'order': moduleOrder,
    'pending-order': modulePendingOrder,
    'quote' : moduleQuote,
    'country': moduleCountry,
    'state': moduleState,
    'city': moduleCity,
    'bank': moduleBank,
    'maintenance': moduleMaintenance,
    'currency': moduleCurrency,
    'admin-bank-account': moduleAdminBankAccount,
    'fund': moduleFund
  },
  strict: process.env.NODE_ENV !== 'production'
})
