import { v4 as uuidv4 } from 'uuid'
import { Storage } from 'aws-amplify'

export const generateParams = (filterSortPage, filter) => {
  const serverQueryObj = {}

  if (filterSortPage !== null) {
    if (filterSortPage.hasOwnProperty('page')) {
      serverQueryObj.page = filterSortPage.page + 1
    }

    if (filterSortPage.hasOwnProperty('rows')) {
      serverQueryObj.rows = filterSortPage.rows
    }

    if (filterSortPage.sortField !== null) {
      serverQueryObj.sortField = filterSortPage.sortField
      serverQueryObj.sortOrder = filterSortPage.sortOrder === 1 ? 'asc' : 'desc'
    }
  }

  if (filter.global !== '') {
    serverQueryObj.globalSearch = filter.global
  }

  return serverQueryObj
}

export const formatDate = (date) => {
  return (new Date(date)).toISOString().split('T')[0]
}

export const getUniqueFileName = (file) => {
  const extention = file.name.split('.').reverse()[0]
  return `${uuidv4()}.${extention}`
}

export const uploadFileToS3 = async (filePath, file) => {
  return await Storage.put(filePath, file, {
    contentType: file.type
  })
}
