import employeeService from '@/services/EmployeeService'

export default {
  getEmployeeDatatable ({commit}, {eventForServer, filter}) {
    return employeeService.getEmployeeDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_EMPLOYEES', data.payload)
        return data
      })
  },

  getEmployee ({commit}, employeeId) {
    return employeeService.getEmployee(employeeId)
      .then(({data}) => {
        // commit('SET_EMPLOYEE', data.payload)
        return data
      })
  },

  createEmployee ({commit}, payload) {
    return employeeService.createEmployee(payload)
      .then(({data}) => {
        commit('CREATE_EMPLOYEE', data.payload)
        return data
      })
  },

  updateEmployee ({commit}, payload) {
    return employeeService.updateEmployee(payload)
      .then(({data}) => {
        commit('UPDATE_EMPLOYEE', data.payload)
        return data
      })
  },

  deleteEmployee ({commit}, employeeId) {
    return employeeService.deleteEmployee(employeeId)
      .then(({data}) => {
        commit('DELETE_EMPLOYEE', employeeId)
        return data
      })
  }
}
