import orderService from '@/services/OrderService'

export default {
  getOrderDatatable ({ commit }, {
    eventForServer,
    filter
  }) {
    return orderService.getOrderDatatable(eventForServer, filter)
      .then(({ data }) => {
        commit('SET_ORDERS', data.payload)
        return data
      })
  },

  createOrder ({ commit }, payload) {
    return orderService.createOrder(payload)
      .then(({ data }) => {
        commit('CREATE_ORDER', data.payload)
        return data
      })
  },

  getOrder ({ commit }, orderId) {
    return orderService.getOrder(orderId)
      .then(({ data }) => {
        // commit('SET_ORDER', data.payload)
        return data
      })
  },

  updateOrder ({ commit }, payload) {
    return orderService.updateOrder(payload)
      .then(({ data }) => {
        // commit('UPDATE_ORDER', data.payload)
        return data
      })
  },

  deleteOrder ({ commit }, orderId) {
    return orderService.deleteOrder(orderId)
      .then(({ data }) => {
        commit('DELETE_ORDER', orderId)
        return data
      })
  },


  /*
   * Delete Single Order Detail
   */
  deleteOrderDetail ({ commit }, orderDetailId) {
    return orderService.deleteOrderDetail(orderDetailId)
      .then(({ data }) => {
        // commit('DELETE_ORDER', orderId)
        return data
      })
  },

  /*
   * Get Currency with Order Service and It's Details
   */
  orderService ({ commit }, payload) {
    return orderService.orderService(payload)
      .then(({ data }) => {
        return data
      })
  }
}
