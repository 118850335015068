import maintenanceService from '@/services/MaintenanceService'

export default {
  getMaintenanceDatatable ({ commit }, {
    eventForServer,
    filter
  }) {
    return maintenanceService.getMaintenanceDatatable(eventForServer, filter)
      .then(({ data }) => {
        commit('SET_MAINTENANCES', data.payload)
        return data
      })
  },

  getMaintenance ({ commit }, maintenanceId) {
    return maintenanceService.getMaintenance(maintenanceId)
      .then(({ data }) => {
        // commit('CREATE_MAINTENANCE', data.payload)
        return data
      })
  },

  createMaintenance ({ commit }, payload) {
    return maintenanceService.createMaintenance(payload)
      .then(({ data }) => {
        commit('CREATE_MAINTENANCE', data.payload)
        return data
      })
  },

  /*
  * Get All Maintenance Services
   */
  getAllMaintenanceServices ({ commit}) {
    return maintenanceService.getAllMaintenanceServices()
      .then(({data}) => {
        // commit('SET_MAINTENANCE_SERVICES', data.payload)
        return data
      })
  }
}
