export default {
  SET_QUOTES (state, quotes) {
    state.quotes = quotes
  },

  CREATE_QUOTE (state, quote) {
    state.quotes.data.unshift(quote)
  },

  DELETE_QUOTE (state, quoteId) {
    state.quotes.data = state.quotes.data.filter(q => q.quote_id != quoteId)
  }
}
