export default {
  SET_DRIVERS (state, drivers) {
    state.drivers = drivers
  },

  SET_DRIVER (state, driver) {
    state.driver = driver
  },

  CREATE_DRIVER (state, driver) {
    state.drivers.data.unshift(driver)
  },

  UPDATE_DRIVER (state, driver) {
    state.drivers.data = state.drivers.data.map((d) => {
      return (d.user_id == driver.user_id) ? (d = driver) : d
    })
  },

  DELETE_DRIVER (state, driverId) {
    state.drivers.data = state.drivers.data.filter(d => d.user_id != driverId)
  }
}
