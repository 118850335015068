/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '../../http/axios/index.js'

export default {
  // SET_BEARER (state, accessToken) {
  //   axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  // }

  LOGIN_USER (state, user) {
    state.user = user
    // window.currentLoggedInUser = user
    localStorage.setItem('userInfo', JSON.stringify(user))
  },

  FETCH_USER (state, user) {
    state.user = user
  },

  LOGOUT_USER (state) {
    state.user = null
    // window.currentLoggedInUser = ''
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userInfo')
  },

  REGISTER_USER (state, user) {
    state.user = user
    localStorage.setItem('userInfo', JSON.stringify(user))
  }
}
