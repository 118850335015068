import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getCityDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/cities/datatable', {
      params: serverQueryObj
    })
  },

  async getAllCities () {
    return await axios.get('/cities')
  }
}
