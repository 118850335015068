import service from '@/services/PoiService'

export default {
  getPoiDatatable ({commit}, {eventForServer, filter}) {
    return service.getPoiDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_POIS', data.payload)
        return data
      })
  },

  createPoi ({commit}, payload) {
    return service.createPoi(payload)
      .then(({data}) => {
        // commit('CREATE_SERVICE', data.payload)
        return data
      })
  },

  updatePoi ({commit}, payload) {
    return service.updatePoi(payload)
      .then(({data}) => {
        // commit('CREATE_SERVICE', data.payload)
        return data
      })
  },

  getAllService ({commit}) {
    return service.getAllService()
      .then(({data}) => {
        commit('SET_SERVICES', data.payload)
        return data
      })
  },



  updateService ({commit}, payload) {
    return service.updateService(payload)
      .then(({data}) => {
        commit('UPDATE_SERVICE', data.payload)
        return data
      })
  },

  deletePoi ({commit}, poiId) {
    return service.deletePoi(poiId)
      .then(() => {
        commit('DELETE_POI', poiId)
      })
  }
}
