/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import('./views/Home.vue'),
          meta: {
            needAuth: true,
            rule: 'client_employee'
          }
        },
        {
          path: '/product-service',
          name: 'page-product-service',
          component: () => import('./views/pages/product-service/ProductServiceDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'channel_partner_employee'
          }
        },
        {
          path: '/channel-partners',
          name: 'page-channel-partners',
          component: () => import('./views/pages/channel-partner/ChannelPartnerDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'channel_partner'
          }
        },
        {
          path: '/channel-partners/add',
          name: 'page-channel-partners-add',
          component: () => import('./views/pages/channel-partner/AddChannelPartner.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Channel Partner',
                url: '/channel-partners'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Channel Partner',
            rule: 'channel_partner',
            needAuth: true,
            parent: 'page-channel-partners'
          }
        },
        {
          path: '/channel-partners/:id/edit',
          name: 'page-channel-partners-edit',
          component: () => import('./views/pages/channel-partner/EditChannelPartner.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Channel Partner',
                url: '/channel-partners'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            pageTitle: 'Edit Channel Partner',
            rule: 'channel_partner',
            needAuth: true,
            parent: 'page-channel-partners'
          }
        },
        {
          path: '/channel-partners/:id',
          name: 'page-channel-partners-view',
          component: () => import('./views/pages/channel-partner/ShowChannelPartner.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Channel Partner',
                url: '/channel-partners'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Channel Partner View',
            rule: 'channel_partner',
            needAuth: true,
            parent: 'page-channel-partners'
          }
        },
        {
          path: '/channel-partners/:id/assign',
          name: 'page-product-service-assign',
          component: () => import('./views/pages/channel-partner/AssignProductService.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Channel Partner',
                url: '/channel-partners'
              },
              {
                title: 'Assign',
                active: true
              }
            ],
            pageTitle: 'Assign Product/Service',
            rule: 'channel_partner',
            needAuth: true,
            parent: 'page-channel-partners'
          }
        },
        {
          path: '/routes',
          name: 'page-routes',
          component: () => import('./views/pages/route/RouteDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/routes/add',
          name: 'page-routes-add',
          component: () => import('./views/pages/route/AddRoute.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Routes',
                url: '/routes'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Route',
            rule: 'admin',
            parent: 'page-routes',
            needAuth: true
          }
        },
        {
          path: '/routes/:id',
          name: 'page-routes-view',
          component: () => import('./views/pages/route/ShowRoute.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Routes',
                url: '/routes'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Route View',
            rule: 'admin',
            parent: 'page-routes',
            needAuth: true
          }
        },
        {
          path: '/routes/:id/edit',
          name: 'page-routes-edit',
          component: () => import('./views/pages/route/EditRoute.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Routes',
                url: '/routes'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            pageTitle: 'Edit Route',
            rule: 'admin',
            parent: 'page-routes',
            needAuth: true
          }
        },
        {
          path: '/employees',
          name: 'page-employees',
          component: () => import('./views/pages/employee/EmployeeDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client'
          }
        },
        {
          path: '/employees/add',
          name: 'page-employees-add',
          component: () => import('./views/pages/employee/AddEmployee.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Employee',
                url: '/employees'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Employee',
            rule: 'client',
            parent: 'page-employees',
            needAuth: true
          }
        },
        {
          path: '/employees/:id',
          name: 'page-employees-view',
          component: () => import('./views/pages/employee/ShowEmployee.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Employee',
                url: '/employees'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Employee View',
            rule: 'client',
            parent: 'page-employees',
            needAuth: true
          }
        },
        {
          path: '/employees/:id/edit',
          name: 'page-employees-edit',
          component: () => import('./views/pages/employee/EditEmployee.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Employee',
                url: '/employees'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            pageTitle: 'Edit Employee',
            rule: 'client',
            parent: 'page-employees',
            needAuth: true
          }
        },
        {
          path: '/drivers',
          name: 'page-drivers',
          component: () => import('./views/pages/driver/DriverDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client'
          }
        },
        {
          path: '/drivers/add',
          name: 'page-drivers-add',
          component: () => import('./views/pages/driver/AddDriver.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Driver',
                url: '/drivers'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Driver',
            rule: 'client',
            parent: 'page-drivers',
            needAuth: true
          }
        },
        {
          path: '/drivers/:id',
          name: 'page-drivers-view',
          component: () => import('./views/pages/driver/ShowDriver.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Driver',
                url: '/drivers'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Driver View',
            rule: 'client',
            parent: 'page-drivers',
            needAuth: true
          }
        },
        {
          path: '/drivers/:id/edit',
          name: 'page-drivers-edit',
          component: () => import('./views/pages/driver/EditDriver.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Driver',
                url: '/drivers'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            pageTitle: 'Edit Driver',
            rule: 'client',
            parent: 'page-drivers',
            needAuth: true
          }
        },
        {
          path: '/trucks',
          name: 'page-trucks',
          component: () => import('./views/pages/truck/TruckDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client'
          }
        },
        {
          path: '/trucks/add',
          name: 'page-trucks-add',
          component: () => import('./views/pages/truck/AddTruck.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                // title: 'Truck',
                title: 'Vehicle',
                url: '/trucks'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            // pageTitle: 'Add Truck',
            pageTitle: 'Add Vehicle',
            rule: 'client',
            parent: 'page-trucks',
            needAuth: true
          }
        },
        {
          path: '/trucks/:id',
          name: 'page-trucks-view',
          component: () => import('./views/pages/truck/ShowTruck.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Vehicle',
                // title: 'Trucks',
                url: '/trucks'
              },
              {
                title: 'View',
                active: true
              }
            ],
            // pageTitle: 'Truck View',
            pageTitle: 'Vehicle View',
            rule: 'client',
            parent: 'page-trucks',
            needAuth: true
          }
        },
        {
          path: '/trucks/:id/edit',
          name: 'page-trucks-edit',
          component: () => import('./views/pages/truck/EditTruck.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                // title: 'Truck',
                title: 'Vehicle',
                url: '/trucks'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            // pageTitle: 'Edit Truck',
            pageTitle: 'Edit Vehicle',
            rule: 'client',
            parent: 'page-trucks',
            needAuth: true
          }
        },
        {
          path: '/trailers',
          name: 'page-trailers',
          component: () => import('./views/pages/trailer/TrailerDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client'
          }
        },
        {
          path: '/trailers/add',
          name: 'page-trailers-add',
          component: () => import('./views/pages/trailer/AddTrailer.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Trailer',
                url: '/trailers'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Trailer',
            rule: 'client',
            parent: 'page-trailers',
            needAuth: true
          }
        },
        {
          path: '/trailers/:id',
          name: 'page-trailers-view',
          component: () => import('./views/pages/trailer/ShowTrailer.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Trailer',
                url: '/trailers'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Trailer View',
            rule: 'client',
            parent: 'page-trailers',
            needAuth: true
          }
        },
        {
          path: '/trailers/:id/edit',
          name: 'page-trailers-edit',
          component: () => import('./views/pages/trailer/EditTrailer.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Trailer',
                url: '/trailers'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            pageTitle: 'Edit Trailer',
            rule: 'client',
            parent: 'page-trailers',
            needAuth: true
          }
        },
        {
          path: '/orders',
          name: 'page-orders',
          component: () => import('./views/pages/order/OrderDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client_employee'
          }
        },
        {
          path: '/orders/add',
          name: 'page-orders-add',
          component: () => import('./views/pages/order/AddOrder.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Order',
                url: '/orders'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Order',
            rule: 'client_employee',
            parent: 'page-orders',
            needAuth: true
          }
        },
        {
          path: '/orders/:id',
          name: 'page-orders-view',
          component: () => import('./views/pages/order/ShowOrder.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Order',
                url: '/orders'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Order View',
            rule: 'client_employee',
            parent: 'page-orders',
            needAuth: true
          }
        },
        {
          path: '/orders/:id/edit',
          name: 'page-orders-edit',
          component: () => import('./views/pages/order/EditOrder.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Order',
                url: '/orders'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            pageTitle: 'Edit Order',
            rule: 'client_employee',
            parent: 'page-orders',
            needAuth: true
          }
        },
        {
          path: '/pending-orders',
          name: 'page-pending-orders',
          component: () => import('./views/pages/pending-order/PendingOrderDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'channel_partner_employee'
          }
        },
        {
          path: '/pending-orders/:id',
          name: 'page-pending-orders-view',
          component: () => import('./views/pages/pending-order/ShowPendingOrder.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Pending Orders',
                url: '/pending-orders'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Pending Order View',
            rule: 'channel_partner_employee',
            parent: 'page-pending-orders',
            needAuth: true
          }
        },
        {
          path: '/invoices',
          name: 'page-invoices',
          component: () => import('./views/pages/invoice/InvoiceDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'channel_partner'
          }
        },
        {
          path: '/invoices/add',
          name: 'page-invoices-add',
          component: () => import('./views/pages/invoice/AddInvoice.vue'),
          props: true,
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Invoice',
                url: '/invoices'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Invoice',
            rule: 'channel_partner',
            parent: 'page-invoices',
            needAuth: true
          }
        },
        {
          path: '/quotes',
          name: 'page-quotes',
          component: () => import('./views/pages/quote/QuoteDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client_employee'
          }
        },
        {
          path: '/quotes/add',
          name: 'page-quotes-add',
          component: () => import('./views/pages/quote/AddQuote.vue'),
          props: true,
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Quote',
                url: '/quotes'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Quote',
            rule: 'client_employee',
            parent: 'page-quotes',
            needAuth: true
          }
        },
        {
          path: '/quotes/:id',
          name: 'page-quotes-view',
          component: () => import('./views/pages/quote/ShowQuote.vue'),
          props: true,
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Quote',
                url: '/quotes'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Quote View',
            rule: 'client_employee',
            parent: 'page-quotes',
            needAuth: true
          }
        },
        {
          path: '/quotes/create-invoice',
          name: 'page-quotes-add-invoice',
          component: () => import('./views/pages/quote/AddQuoteInvoice.vue'),
          props: true,
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Quote',
                url: '/quotes'
              },
              {
                title: 'Add Invoice',
                active: true
              }
            ],
            pageTitle: 'Add Invoice',
            rule: 'client_employee',
            parent: 'page-quotes',
            needAuth: true
          }
        },
        {
          path: '/clients',
          name: 'page-clients',
          component: () => import('./views/pages/client/ClientDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/clients/add',
          name: 'page-clients-add',
          component: () => import('./views/pages/client/AddClient.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Client',
                url: '/clients'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Client',
            rule: 'admin',
            parent: 'page-clients',
            needAuth: true
          }
        },
        {
          path: '/clients/:id',
          name: 'page-clients-view',
          component: () => import('./views/pages/client/ShowClient.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Client',
                url: '/clients'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Client View',
            rule: 'admin',
            parent: 'page-clients',
            needAuth: true
          }
        },
        {
          path: '/clients/:id/edit',
          name: 'page-clients-edit',
          component: () => import('./views/pages/client/EditClient.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Client',
                url: '/clients'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            pageTitle: 'Edit Client',
            rule: 'admin',
            parent: 'page-clients',
            needAuth: true
          }
        },
        {
          path: '/poi',
          name: 'page-poi',
          component: () => import('./views/pages/point-of-interest/POIDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/poi/add',
          name: 'page-poi-add',
          component: () => import('./views/pages/point-of-interest/AddPOI.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'POI',
                url: '/poi'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add POI',
            rule: 'admin',
            parent: 'page-poi',
            needAuth: true
          }
        },
        {
          path: '/poi/:id',
          name: 'page-poi-view',
          component: () => import('./views/pages/point-of-interest/ShowPOI.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'POI',
                url: '/poi'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'POI View',
            rule: 'admin',
            parent: 'page-poi',
            needAuth: true
          }
        },
        {
          path: '/poi/:id/edit',
          name: 'page-poi-edit',
          component: () => import('./views/pages/point-of-interest/EditPOI.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'POI',
                url: '/poi'
              },
              {
                title: 'Edit',
                active: true
              }
            ],
            pageTitle: 'Edit POI',
            rule: 'admin',
            parent: 'page-poi',
            needAuth: true
          }
        },
        {
          path: '/maintenance',
          name: 'page-maintenance',
          component: () => import('./views/pages/maintenance/MaintenanceDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client'
          }
        },
        {
          path: '/maintenance/add',
          name: 'page-maintenance-add',
          component: () => import('./views/pages/maintenance/AddMaintenance.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Maintenance',
                url: '/maintenance'
              },
              {
                title: 'Add',
                active: true
              }
            ],
            pageTitle: 'Add Maintenance',
            rule: 'client',
            parent: 'page-maintenance',
            needAuth: true
          }
        },
        {
          path: '/maintenance/:id',
          name: 'page-maintenance-view',
          component: () => import('./views/pages/maintenance/ShowMaintenance.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Maintenance',
                url: '/maintenance'
              },
              {
                title: 'View',
                active: true
              }
            ],
            pageTitle: 'Maintenance View',
            rule: 'client',
            parent: 'page-maintenance',
            needAuth: true
          }
        },
        {
          path: '/funds',
          name: 'page-funds',
          component: () => import('./views/pages/fund/client/FundDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client'
          }
        },
        {
          path: '/fund-requests',
          name: 'page-fund-requests',
          component: () => import('./views/pages/fund/admin/fund-request/FundRequestDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/client-funds',
          name: 'page-client-funds',
          component: () => import('./views/pages/fund/admin/client-fund/ClientFundDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/fund-history',
          name: 'page-fund-history',
          component: () => import('./views/pages/fund-history/FundHistoryDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'client'
          }
        },
        {
          path: '/countries',
          name: 'page-countries',
          component: () => import('./views/pages/country/CountryDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/states',
          name: 'page-states',
          component: () => import('./views/pages/state/StateDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/cities',
          name: 'page-cities',
          component: () => import('./views/pages/city/CityDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/banks',
          name: 'page-banks',
          component: () => import('./views/pages/banks/BankDatatable.vue'),
          meta: {
            needAuth: true,
            rule: 'admin'
          }
        },
        {
          path: '/change-password',
          name: 'page-change-password',
          component: () => import('./views/pages/ChangePassword.vue'),
          meta: {
            breadcrumb: [
              {
                title: 'Home',
                url: '/'
              },
              {
                title: 'Change Password',
                active: true
              }
            ],
            pageTitle: 'Change Password',
            rule: 'client',
            needAuth: true
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'visitor',
            authRoute: true
          }
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'visitor'
            // authRoute: true
          }
        },
        // {
        //   path: '/register',
        //   name: 'page-register',
        //   component: () => import('@/views/pages/register/Register.vue'),
        //   meta: {
        //     rule: 'visitor',
        //     authRoute: true
        //   }
        // },
        {
          path: '/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'visitor',
            authRoute: true
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404',
      meta: {
        rule: 'visitor'
      }
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.needAuth)) {
    if (store.getters['auth/isLoggedIn'] === 0 && (localStorage.getItem('accessToken') === null)) {
      next({
        name: 'login'
      })
    } else if (store.getters['auth/isLoggedIn'] === 0 && (localStorage.getItem('accessToken') !== null)) {
      try {
        await store.dispatch('auth/getLoggedInUserProfile')
        next()
      } catch (e) {
        await store.dispatch('auth/logoutLocally')
        next({
          name: 'login'
        })
      }

    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.authRoute)) {
    if (store.getters['auth/isLoggedIn'] === 1) {
      next({
        name: 'home'
      })
    } else if (localStorage.getItem('accessToken') !== null) {
      try {
        await store.dispatch('auth/getLoggedInUserProfile')
        next({
          name: 'home'
        })
      } catch (e) {
        await store.dispatch('auth/logoutLocally')
        next()
      }
    } else {
      next()
    }

  } else {
    next()
  }

})

export default router
