import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getTrailerDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('trailers/datatable', {
      params: serverQueryObj
    })
  },

  async getAllTrailers () {
    return await axios.get('/trailers')
  },

  async getTrailer (trailerId) {
    return await axios.get(`/trailers/${trailerId}`)
  },

  async createTrailer (trailer) {
    return await axios.post('/trailers', trailer)
  },

  async updateTrailer (trailer) {
    return await axios.patch(`/trailers/${trailer.id}`, trailer)
  },

  async deleteTrailer (trailerId) {
    return await axios.delete(`/trailers/${trailerId}`)
  },

  async assignTruck ({trailer_id, truck_id}) {
    return await axios.patch(`/trailers/${trailer_id}/assign`, {truck_id})
  }
}
