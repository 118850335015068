export default {
  SET_POIS (state, pois) {
    state.pois = pois
  },

  CREATE_SERVICE (state, service) {
    state.services.data.unshift(service)
  },

  UPDATE_SERVICE (state, service) {
    state.services.data = state.services.data.map((s) => {
      return (s.service_id == service.service_id) ? (s = service) : s
    })
  },

  DELETE_POI (state, poiId) {
    state.pois.data = state.pois.data.filter((poi) => (poi.poi_id != poiId))
  }
}
