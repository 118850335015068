/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  user: (state) => state.user,

  isLoggedIn: (state) => {
    if (state.user === null || state.user === '') {
      return 0
    }
    return 1
  },

  getRole: (state) => {
    if (!state.user) return false
    return state.user.role
  },

  isAdmin: (state) => (state.user.role === 'admin'),

  isChannelPartner: (state) => (state.user.role === 'channel_partner')
}
