import service from '@/services/ServiceService'

export default {
  getServiceDatatable ({commit}, {eventForServer, filter}) {
    return service.getServiceDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_SERVICES', data.payload)
        return data
      })
  },

  getAllService ({commit}) {
    return service.getAllService()
      .then(({data}) => {
        commit('SET_SERVICES', data.payload)
        return data
      })
  },

  createService ({commit}, payload) {
    return service.createService(payload)
      .then(({data}) => {
        commit('CREATE_SERVICE', data.payload)
        return data
      })
  },

  updateService ({commit}, payload) {
    return service.updateService(payload)
      .then(({data}) => {
        commit('UPDATE_SERVICE', data.payload)
        return data
      })
  },

  deleteService ({commit}, serviceId) {
    return service.deleteService(serviceId)
      .then(() => {
        commit('DELETE_SERVICE', serviceId)
      })
  }
}
