import state from './moduleStateState.js'
import mutations from './moduleStateMutations.js'
import actions from './moduleStateActions.js'
import getters from './moduleStateGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
