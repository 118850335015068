import state from './moduleRouteState.js'
import mutations from './moduleRouteMutations.js'
import actions from './moduleRouteActions.js'
import getters from './moduleRouteGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
