import fundService from '@/services/FundService'

export default {
  /*
  * ----------------------------------------------------------------------------
  * --------------------------------  Admin  -----------------------------------
  * ----------------------------------------------------------------------------
  */
  getFundRequestDatatable ({ commit }, {
    eventForServer,
    filter
  }) {
    return fundService.getFundRequestDatatable(eventForServer, filter)
      .then(({ data }) => {
        commit('SET_REQUESTED_FUNDS', data.payload)
        return data
      })
  },

  getClientAvailableFundDatatable ({ commit }, {
    eventForServer,
    filter
  }) {
    return fundService.getClientAvailableFundDatatable(eventForServer, filter)
      .then(({ data }) => {
        commit('SET_AVAILABLE_FUNDS', data.payload)
        return data
      })
  },

  getFundHistoryDatatable ({ commit }, {
    eventForServer,
    filter
  }) {
    return fundService.getFundHistoryDatatable(eventForServer, filter)
      .then(({ data }) => {
        commit('SET_FUND_HISTORY', data.payload)
        return data
      })
  },

  changeFundState ({ commit }, payload) {
    return fundService.changeFundState(payload.fund_request_id, payload.status)
      .then(({ data }) => {
        commit('DELETE_REQUESTED_FUND', payload.fund_request_id)
        return data
      })
  },

  /*
 * Client Admin : Fund Datatable
  */
  getAvailableFundDatatable ({ commit }, {
    eventForServer,
    filter
  }) {
    return fundService.getAvailableFundDatatable(eventForServer, filter)
      .then(({ data }) => {
        commit('SET_AVAILABLE_FUNDS', data.payload)
        return data
      })
  },

  getClientFundHistoryDatatable ({ commit }, {
    eventForServer,
    filter
  }) {
    return fundService.getClientFundHistoryDatatable(eventForServer, filter)
      .then(({ data }) => {
        commit('SET_FUND_HISTORY', data.payload)
        return data
      })
  },

  /*
  * Client Admin : Add Fund
   */
  createRequestedFund ({ commit }, payload) {
    return fundService.createRequestedFund(payload)
      .then(({ data }) => {
        // commit('CREATE_REQUESTED_FUND', data.payload)
        return data
      })
  }
}
