import adminBankAccountService from '@/services/AdminBankAccountService'

export default {
  
  getAllAdminBankAccounts ({commit}) {
    return adminBankAccountService.getAllAdminBankAccounts()
      .then(({data}) => {
        // commit('SET_ADMIN_BANK_ACCOUNTS', data.payload)
        return data
      })
  }
}
