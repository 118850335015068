import state from './modulePendigOrderState.js'
import mutations from './modulePendigOrderMutations.js'
import actions from './modulePendigOrderActions.js'
import getters from './modulePendigOrderGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
