import state from './moduleFundState.js'
import mutations from './moduleFundMutations.js'
import actions from './moduleFundActions.js'
import getters from './moduleFundGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
