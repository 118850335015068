/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Amplify
import Amplify from 'aws-amplify'
import awsconfig from './amplify/awsManualConfig'

Amplify.configure(awsconfig)

// Vuesax Component Framework
import Vuesax from 'vuesax'

Vue.use(Vuesax)

// import Vuetify from 'vuetify'
// Vue.use(Vuetify)

// ACL
import acl from './acl/acl'

// axios
import axios from './axios.js'

Vue.prototype.$http = axios

// Filters
import './filters/filters.js'


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'

Vue.use(VueHammer)

// VeeValidate
import VeeValidate, {Validator} from 'vee-validate'

Validator.extend('phone_number', {
  validate (value) {
    return /^[0-9]{10}$/.test(value)
  },
  getMessage (field) {
    return `The ${field} must contain valid phone number.`
  }
})

Vue.use(VeeValidate)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// Global Mixins
// Note: this mixins avalibale every single components
import roleMixin from './mixins/roleMixin'

Vue.mixin(roleMixin)

// import LoadScript from 'vue-plugin-load-script';
// Vue.use(LoadScript);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  acl,
  render: h => h(App)
}).$mount('#app')
