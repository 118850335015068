import Vue from 'vue'
import {AclInstaller, AclCreate, AclRule} from 'vue-acl'
import router from '@/router'

Vue.use(AclInstaller)

let initialRole = 'admin'

const userInfo = JSON.parse(localStorage.getItem('userInfo'))
if (userInfo && userInfo.role) initialRole = userInfo.role

export default new AclCreate({
  initial: initialRole,
  notfound: '/pages/not-authorized',
  router,
  acceptLocalRules: true,
  globalRules: {
    admin: new AclRule('admin').generate(),
    channel_partner: new AclRule('channel_partner').or('admin').generate(),
    client: new AclRule('client').or('channel_partner').or('admin').generate(),
    client_employee: new AclRule('client_employee').or('channel_partner_employee').or('channel_partner').or('client').or('admin').generate(),
    channel_partner_employee: new AclRule('channel_partner_employee').or('client_employee').or('channel_partner').or('client').or('admin').generate(),
    visitor: new AclRule('visitor').or('admin').or('channel_partner').or('client').or('client_employee').or('channel_partner_employee').generate()
  }
  // middleware: async acl => {
  //   await timeout(2000) // call your api
  //   acl.change('admin')
  // }
})
