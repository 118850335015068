import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getStateDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/states/datatable', {
      params: serverQueryObj
    })
  },

  async getAllStates () {
    return await axios.get('/states')
  },

  // Get All Cities of Single State
  async getAllCities (stateId) {
    return await axios.get(`/states/${stateId}/cities`)
  }
}
