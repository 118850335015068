import currencyService from '@/services/CurrencyService'

export default {
  
  getAllCountries ({commit}) {
    return currencyService.getAllCurrencies()
      .then(({data}) => {
        // commit('SET_CURRENCIES', data.payload)
        return data
      })
  }
}
