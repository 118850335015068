import state from './moduleQuoteState.js'
import mutations from './moduleQuoteMutations.js'
import actions from './moduleQuoteActions.js'
import getters from './moduleQuoteGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
