import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getPartnerServiceDetailDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/channel-partner-service-details/datatable', {
      params: serverQueryObj
    })
  },

  async createPartnerServiceDetail (partnerServiceDetail) {
    return await axios.post('/channel-partner-service-details', partnerServiceDetail)
  },

  async updatePartnerServiceDetail ({id, title, price}) {
    return await axios.patch(
      `/channel-partner-service-details/${id}`,
      {
        title,
        price
      }
    )
  }
}
