import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getPendingOrderDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('channel-partners/order/pending', {
      params: serverQueryObj
    })
  },

  async getPendingOrder (orderId) {
    return await axios.get(`/orders/${orderId}?pending`)
  }
}
