export default {
  SET_ORDERS (state, orders) {
    state.orders = orders
  },

  SET_ORDER (state, order) {
    state.order = order
  },

  CREATE_ORDER (state, order) {
    state.orders.data.unshift(order)
  },

  UPDATE_ORDER (state, order) {
    state.orders.data = state.orders.data.map((o) => {
      return (o.order_id == order.order_id) ? (o = order) : o
    })
  },

  DELETE_ORDER (state, orderId) {
    state.orders.data = state.orders.data.filter(o => o.order_id != orderId)
  }
}
