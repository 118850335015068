import PartnerServiceDetail from '@/services/PartnerServiceDetailService'

export default {
  getPartnerServiceDetailDatatable ({commit}, {eventForServer, filter}) {
    return PartnerServiceDetail.getPartnerServiceDetailDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_PARTNER_SERVICE_DETAILS', data.payload)
        return data
      })
  },

  createPartnerServiceDetail ({commit}, payload) {
    return PartnerServiceDetail.createPartnerServiceDetail(payload)
      .then(({data}) => {
        commit('CREATE_PARTNER_SERVICE_DETAIL', data.payload)
        return data
      })
  },

  updatePartnerServiceDetail ({commit}, payload) {
    return PartnerServiceDetail.updatePartnerServiceDetail(payload)
      .then(({data}) => {
        commit('UPDATE_PARTNER_SERVICE_DETAIL', data.payload)
        return data
      })
  }
}
