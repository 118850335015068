import state from './moduleAdminBankAccountState.js'
import mutations from './moduleAdminBankAccountMutations.js'
import actions from './moduleAdminBankAccountActions.js'
import getters from './moduleAdminBankAccountGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
