export default {
  SET_REQUESTED_FUNDS (state, funds) {
    state.requestedFunds = funds
  },

  SET_FUND_HISTORY (state, funds) {
    state.fundHistory = funds
  },

  DELETE_REQUESTED_FUND (state, fundRequestId) {
    state.requestedFunds.data = state.requestedFunds.data.filter(fund => {
      return (fund.fund_request_id != fundRequestId)
    })
  },

  SET_AVAILABLE_FUNDS (state, funds) {
    state.availableFunds = funds
  }
}
