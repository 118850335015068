import cityService from '@/services/CityService'

export default {
  getCityDatatable ({commit}, {
    eventForServer,
    filter
  }) {
    return cityService.getCityDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_CITIES', data.payload)
        return data
      })
  },

  getAllCities ({commit}) {
    return cityService.getAllCities()
      .then(({data}) => {
        // commit('SET_STATES', data.payload)
        return data
      })
  }
}
