import state from './moduleMaintenanceState.js'
import mutations from './moduleMaintenanceMutations.js'
import actions from './moduleMaintenanceActions.js'
import getters from './moduleMaintenanceGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
