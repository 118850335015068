import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getTruckDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('trucks/datatable', {
      params: serverQueryObj
    })
  },

  async getAllTrucks () {
    return await axios.get('/trucks')
  },

  async getTruck (truckId) {
    return await axios.get(`/trucks/${truckId}`)
  },

  async createTruck (truck) {
    return await axios.post('/trucks', truck)
  },

  async updateTruck (truck) {
    return await axios.patch(`/trucks/${truck.id}`, truck)
  },

  async deleteTruck (truckId) {
    return await axios.delete(`/trucks/${truckId}`)
  },

  async assignTrailers ({truck_id, trailer_ids}) {
    return await axios.patch(`/trucks/${truck_id}/assign`, {trailer_ids})
  }
}
