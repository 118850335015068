export default {
  SET_PARTNER_SERVICE_DETAILS (state, partnerServiceDetails) {
    state.partnerServiceDetails = partnerServiceDetails
  },

  CREATE_PARTNER_SERVICE_DETAIL (state, partnerServiceDetail) {
    state.partnerServiceDetails.data.unshift(partnerServiceDetail)
  },

  UPDATE_PARTNER_SERVICE_DETAIL (state, partnerServiceDetail) {
    state.partnerServiceDetails.data = state.partnerServiceDetails.data.map((p) => {
      return (p.partner_service_detail_id == partnerServiceDetail.partner_service_detail_id)
        ? (p = partnerServiceDetail)
        : p
    })
  }
}
