export default {
  SET_ROUTES (state, routes) {
    state.routes = routes
  },

  // CREATE_SERVICE (state, service) {
  //   state.services.data.unshift(service)
  // },
  //
  // UPDATE_SERVICE (state, service) {
  //   state.services.data = state.services.data.map((s) => {
  //     return (s.service_id == service.service_id) ? (s = service) : s
  //   })
  // },

  DELETE_ROUTE (state, routeId) {
    state.routes.data = state.routes.data.filter((route) => (route.route_id != routeId))
  }
}
