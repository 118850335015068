export default {
  Auth: {
    identityPoolId: 'eu-central-1:5a993659-b2e8-470e-ab40-0a379ee52a26', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'eu-central-1', // REQUIRED - Amazon Cognito Region
    userPoolId: 'eu-central-1_J8WEgbmJM', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: 'XX-XXXX-X_abcd1234' //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: 'amplifytravelpassf3e5eaa9140c4ce8a9017e5a0b9da2172116-travelenv', //REQUIRED -  Amazon S3 bucket name
      region: 'eu-central-1' //OPTIONAL -  Amazon service region
    }
  }
}
