export default {
  SET_CLIENTS (state, clients) {
    state.clients = clients
  },

  SET_CLIENT (state, client) {
    state.client = client
  },

  CREATE_CLIENT (state, client) {
    state.clients.data.unshift(client)
  },

  UPDATE_CLIENT (state, client) {
    state.clients.data = state.clients.data.map((c) => {
      return (c.user_id == client.user_id) ? (c = client) : c
    })
  },

  DELETE_CLIENT (state, clientId) {
    state.clients.data = state.clients.data.filter(c => c.user_id != clientId)
  }
}
