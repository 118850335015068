export default {
  requestedFund: null,
  requestedFunds: {
    data: [],
    links: {},
    meta: {}
  },

  fundHistory: {
    data: [],
    links: {},
    meta: {}
  },

  availableFund: null,
  availableFunds: {
    data: [],
    links: {},
    meta: {}
  }
}
