export default {
  SET_PARTNERS (state, partners) {
    state.partners = partners
  },

  SET_PARTNER (state, partner) {
    state.selectedPartner = partner
  },

  CREATE_PARTNER (state, partner) {
    state.partners.data.unshift(partner)
  },

  UPDATE_PARTNER (state, partner) {
    state.partners.data = state.partners.data.map((p) => {
      return (p.user_id == partner.user_id) ? (p = partner) : p
    })
  },

  DELETE_PARTNER (state, partnerId) {
    state.partners.data = state.partners.data.filter(p => p.user_id != partnerId)
  }
}
