import driverService from '@/services/DriverService'

export default {
  getDriverDatatable ({commit}, {eventForServer, filter}) {
    return driverService.getDriverDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_DRIVERS', data.payload)
        return data
      })
  },

  getAllDrivers ({commit}, filter) {
    return driverService.getAllDrivers(filter)
      .then(({data}) => {
        // commit('SET_DRIVERS', data.payload)
        return data
      })
  },

  getDriver ({commit}, driverId) {
    return driverService.getDriver(driverId)
      .then(({data}) => {
        // commit('SET_DRIVER', data.payload)
        return data
      })
  },

  createDriver ({commit}, payload) {
    return driverService.createDriver(payload)
      .then(({data}) => {
        commit('CREATE_DRIVER', data.payload)
        return data
      })
  },

  updateDriver ({commit}, payload) {
    return driverService.updateDriver(payload)
      .then(({data}) => {
        commit('UPDATE_DRIVER', data.payload)
        return data
      })
  },

  deleteDriver ({commit}, driverId) {
    return driverService.deleteDriver(driverId)
      .then(({data}) => {
        commit('DELETE_DRIVER', driverId)
        return data
      })
  },

  assignTruck ({commit}, payload) {
    return driverService.assignTruck(payload)
      .then(({data}) => {
        // commit('UPDATE_DRIVER', data.payload)
        return data
      })
  }
}
