import truckService from '@/services/TruckService'

export default {
  getTruckDatatable ({commit}, {eventForServer, filter}) {
    return truckService.getTruckDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_TRUCKS', data.payload)
        return data
      })
  },

  getAllTrucks ({commit}) {
    return truckService.getAllTrucks()
      .then(({data}) => {
        // commit('SET_TRUCKS', data.payload)
        return data
      })
  },

  getTruck ({commit}, truckId) {
    return truckService.getTruck(truckId)
      .then(({data}) => {
        commit('SET_TRUCK', data.payload)
        return data
      })
  },

  createTruck ({commit}, payload) {
    return truckService.createTruck(payload)
      .then(({data}) => {
        commit('CREATE_TRUCK', data.payload)
        return data
      })
  },

  updateTruck ({commit}, payload) {
    return truckService.updateTruck(payload)
      .then(({data}) => {
        commit('UPDATE_TRUCK', data.payload)
        return data
      })
  },

  deleteTruck ({commit}, truckId) {
    return truckService.deleteTruck(truckId)
      .then(({data}) => {
        commit('DELETE_TRUCK', truckId)
        return data
      })
  },

  assignTrailers ({commit}, payload) {
    return truckService.assignTrailers(payload)
      .then(({data}) => {
        // commit('UPDATE_TRUCK', data.payload)
        return data
      })
  },

  unsetTruck ({commit}) {
    commit('UNSET_TRUCK')
  },

  unsetTrucks ({commit}) {
    commit('UNSET_TRUCKS')
  }
}
