import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getMaintenanceDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/maintenances/datatable', {
      params: serverQueryObj
    })
  },

  async getMaintenance (maintenanceId) {
    return await axios.get(`/maintenances/${maintenanceId}`)
  },

  async createMaintenance (maintenance) {
    return await axios.post('/maintenances', maintenance)
  },

  /*
  * Get All Maintenance Services
   */
  async getAllMaintenanceServices () {
    return await axios.get('/maintenance-services')
  }
}
