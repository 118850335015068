import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getClientDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('clients/datatable', {
      params: serverQueryObj
    })
  },

  async getClient (clientId) {
    return await axios.get(`/clients/${clientId}`)
  },

  async createClient (client) {
    return await axios.post('/clients', client)
  },

  async updateClient (client) {
    return await axios.patch(`/clients/${client.id}`, client)
  },

  async deleteClient (clientId) {
    return await axios.delete(`/clients/${clientId}`)
  }
}
