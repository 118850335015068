export default {
  SET_SERVICES (state, services) {
    state.services = services
  },

  CREATE_SERVICE (state, service) {
    state.services.data.unshift(service)
  },

  UPDATE_SERVICE (state, service) {
    state.services.data = state.services.data.map((s) => {
      return (s.service_id == service.service_id) ? (s = service) : s
    })
  },

  DELETE_SERVICE (state, serviceId) {
    state.services.data = state.services.data.filter((s) => (s.service_id != serviceId))
  }
}
