import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getBankDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('/banks/datatable', {
      params: serverQueryObj
    })
  },

  async getAllBanks () {
    return await axios.get('/banks')
  }
}
