export default {
  SET_EMPLOYEES (state, employees) {
    state.employees = employees
  },

  SET_EMPLOYEE (state, employee) {
    state.employee = employee
  },

  CREATE_EMPLOYEE (state, employee) {
    state.employees.data.unshift(employee)
  },

  UPDATE_EMPLOYEE (state, employee) {
    state.employees.data = state.employees.data.map((e) => {
      return (e.user_id == employee.user_id) ? (e = employee) : e
    })
  },

  DELETE_EMPLOYEE (state, employeeId) {
    state.employees.data = state.employees.data.filter(d => d.user_id != employeeId)
  }
}
