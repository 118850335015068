import partnerService from '@/services/PartnerService'

export default {
  getAllPartners ({commit}, {eventForServer, filter}) {
    return partnerService.getAllPartners(eventForServer, filter)
      .then(({data}) => {
        commit('SET_PARTNERS', data.payload)
        return data
      })
  },

  getPartner ({commit}, partnerId) {
    return partnerService.getPartner(partnerId)
      .then(({data}) => {
        commit('SET_PARTNER', data.payload)
        return data
      })
  },

  createPartner ({commit}, payload) {
    return partnerService.createPartner(payload)
      .then(({data}) => {
        commit('CREATE_PARTNER', data.payload)
        return data
      })
  },

  updatePartner ({commit}, payload) {
    return partnerService.updatePartner(payload)
      .then(({data}) => {
        commit('UPDATE_PARTNER', data.payload)
        return data
      })
  },

  deletePartner ({commit}, partnerId) {
    return partnerService.deletePartner(partnerId)
      .then(({data}) => {
        commit('DELETE_PARTNER', partnerId)
        return data
      })
  },

  getAllAssignedServices (context, partnerId) {
    return partnerService.getAllAssignedServices(partnerId).then(({data}) => data)
  },

  assignServices (context, {partnerId, payload}) {
    return partnerService.assignServices(partnerId, payload).then(({data}) => data)
  }
}
