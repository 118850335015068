import trailerService from '@/services/TrailerService'

export default {
  getTrailerDatatable ({commit}, {eventForServer, filter}) {
    return trailerService.getTrailerDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_TRAILERS', data.payload)
        return data
      })
  },

  getAllTrailers ({commit}) {
    return trailerService.getAllTrailers()
      .then(({data}) => {
        // commit('SET_TRAILER', data.payload)
        return data
      })
  },

  getTrailer ({commit}, trailerId) {
    return trailerService.getTrailer(trailerId)
      .then(({data}) => {
        commit('SET_TRAILER', data.payload)
        return data
      })
  },

  createTrailer ({commit}, payload) {
    return trailerService.createTrailer(payload)
      .then(({data}) => {
        commit('CREATE_TRAILER', data.payload)
        return data
      })
  },

  updateTrailer ({commit}, payload) {
    return trailerService.updateTrailer(payload)
      .then(({data}) => {
        commit('UPDATE_TRAILER', data.payload)
        return data
      })
  },

  deleteTrailer ({commit}, trailerId) {
    return trailerService.deleteTrailer(trailerId)
      .then(({data}) => {
        commit('DELETE_TRAILER', trailerId)
        return data
      })
  },

  assignTruck ({commit}, payload) {
    return trailerService.assignTruck(payload)
      .then(({data}) => {
        // commit('UPDATE_TRAILER', data.payload)
        return data
      })
  }
}
