import pendingOrderService from '@/services/PendingOrderService'

export default {
  getPendingOrderDatatable ({commit}, {
    eventForServer,
    filter
  }) {
    return pendingOrderService.getPendingOrderDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_PENDING_ORDERS', data.payload)
        return data
      })
  },

  getPendingOrder ({commit}, orderId) {
    return pendingOrderService.getPendingOrder(orderId)
      .then(({data}) => {
        // commit('SET_PENDING_ORDER', data.payload)
        return data
      })
  }
}
