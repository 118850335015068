import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getPoiDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('pois/datatable', {
      params: serverQueryObj
    })
  },

  async createPoi (poi) {
    return await axios.post('/pois', poi)
  },

  async getAllService () {
    return await axios.get('services')
  },

  async getAllPois () {
    return await axios.get('pois')
  },


  async updatePoi (poi) {
    return await axios.patch(`/pois/${poi.poi_id}`, poi)
  },

  async getSinglePoi (poiId) {
    return await axios.get(`/pois/${poiId}`)
  },

  async deletePoi (poiId) {
    return await axios.delete(`/pois/${poiId}`)
  }
}
