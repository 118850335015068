export default {
  SET_MAINTENANCES (state, maintenances) {
    state.maintenances = maintenances
  },

  SET_MAINTENANCE_SERVICES (state, maintenanceServices) {
    this.maintenanceServices = maintenanceServices
  },

  CREATE_MAINTENANCE (state, maintenance) {
    state.maintenances.data.unshift(maintenance)
  }
}
