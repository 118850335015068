import axios from '../http/axios'
import {generateParams} from '@/helpers/helper'

export default {
  async getEmployeeDatatable (filterSortPage, filter) {
    const serverQueryObj = generateParams(filterSortPage, filter)

    return await axios.get('employees/datatable', {
      params: serverQueryObj
    })
  },

  async getEmployee (employeeId) {
    return await axios.get(`/employees/${employeeId}`)
  },

  async createEmployee (employee) {
    return await axios.post('/employees', employee)
  },

  async updateEmployee (employee) {
    return await axios.patch(`/employees/${employee.id}`, employee)
  },

  async deleteEmployee (employeeId) {
    return await axios.delete(`/employees/${employeeId}`)
  }
}
