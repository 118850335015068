import stateService from '@/services/StateService'

export default {
  getStateDatatable ({commit}, {
    eventForServer,
    filter
  }) {
    return stateService.getStateDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_STATES', data.payload)
        return data
      })
  },

  getAllStates ({commit}) {
    return stateService.getAllStates()
      .then(({data}) => {
        // commit('SET_STATES', data.payload)
        return data
      })
  },

  getAllCities ({commit}, stateId) {
    return stateService.getAllCities(stateId)
      .then(({data}) => {
        // commit('SET_STATES', data.payload)
        return data
      })
  }
}
