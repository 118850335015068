import countryService from '@/services/CountryService'

export default {
  getCountryDatatable ({commit}, {
    eventForServer,
    filter
  }) {
    return countryService.getCountryDatatable(eventForServer, filter)
      .then(({data}) => {
        commit('SET_COUNTRIES', data.payload)
        return data
      })
  },

  getAllCountries ({commit}) {
    return countryService.getAllCountries()
      .then(({data}) => {
        // commit('SET_COUNTRIES', data.payload)
        return data
      })
  },

  // Get All States for Country
  getAllStates ({commit}, countryId) {
    return countryService.getAllStates(countryId)
      .then(({data}) => {
        // commit('SET_COUNTRIES', data.payload)
        return data
      })
  },

  // Get All Banks for Country
  getAllBanks ({commit}, countryId) {
    return countryService.getAllBanks(countryId)
      .then(({data}) => {
        // commit('SET_COUNTRIES', data.payload)
        return data
      })
  }
}
