import state from './modulePartnerServiceDetailState.js'
import mutations from './modulePartnerServiceDetailMutations.js'
import actions from './modulePartnerServiceDetailActions.js'
import getters from './modulePartnerServiceDetailGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
